import * as React from "react";
import Lottie from "react-lottie";
import animationData from "../Lottie/success-animation.json";

interface ISuccessAnimationProps {
  onComplete?: any;
}

export const SuccessAnimation: React.FunctionComponent<
  ISuccessAnimationProps
> = (props) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="succcess-animation">
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        eventListeners={[
          {
            eventName: "complete",
            callback: () => props.onComplete(),
          },
        ]}
      />
    </div>
  );
};
