import * as React from "react";
import { CloseIcon } from "../../Assets";
import {
  BadgeCon,
  BadgeText,
  InputComponent,
  SubjectsContainer,
  Text,
} from "./styled";

interface IUniversityInputComponentProps {
  selectedUniversity: any;
  setselectedUniversity: any;
  setsearchText: any;
  arabic: boolean;
  universities: Array<any>;
  searchText: any;
  searchUniversity: any;
  t: Function;
  setuniversities: any;
  seterrorMessage: Function;
  error: any;
}

export const UniversityInputComponent: React.FunctionComponent<
  IUniversityInputComponentProps
> = (props) => {
  const {
    selectedUniversity,
    setselectedUniversity,
    setsearchText,
    arabic,
    universities,
    searchUniversity,
    searchText,
    setuniversities,
    t,
    seterrorMessage,
    error,
  } = props;

  const [closeMenu, setcloseMenu] = React.useState(false);

  return (
    <div className="res-w-100">
      <div className="d-flex w-100 justify-content-center">
        <div
          className="d-flex res-w-100 flex-row"
          style={{ position: "relative" }}
        >
          <InputComponent
            placeholder={`${
              selectedUniversity.length
                ? t("common:universityAddmorePlaceholder")
                : t("common:universityPlaceholder")
            }`}
            name="name"
            onChange={(e) => {
              seterrorMessage("");
              setsearchText(e.target.value);
              searchUniversity(e);
              setcloseMenu(true);
            }}
            type="text"
            className="search-input"
            autoComplete="off"
            value={searchText}
            arabic={arabic}
          />

          {universities.length && closeMenu ? (
            <div
              className="d-flex flex-column "
              style={{
                background: "#fff",
                position: "absolute",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 8,
                top: 60,
                width: "100%",
                padding: "4px 0 4px 0",
              }}
            >
              {universities.map(
                (a: { id: string; slug: string; name: string }) => (
                  <Text
                    key={a.id}
                    onClick={async () => {
                      setsearchText("");
                      setuniversities([]);
                      setcloseMenu(false);
                      if (selectedUniversity?.length === 5) {
                        seterrorMessage(t("common:universityError"));
                      } else if (
                        selectedUniversity.some(
                          (item: any) => item.slug === a.slug
                        )
                      ) {
                      } else {
                        seterrorMessage("");
                        setuniversities([]);
                        selectedUniversity.push(a);
                        setcloseMenu(false);
                      }
                    }}
                    className="custom-dd-menu"
                    style={{
                      padding: "5px 15px",
                    }}
                    arabic={arabic}
                  >
                    {a.name}
                  </Text>
                )
              )}{" "}
            </div>
          ) : searchText && !universities.length ? (
            <div
              className="d-flex flex-column "
              style={{
                background: "#fff",
                position: "absolute",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 8,
                top: 60,
                width: "100%",
                padding: "10px 0 10px 0",
              }}
            >
              {" "}
              <div
                className="d-flex justify-content-center"
                style={{ color: "#969696" }}
              >
                Loading...
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {error}
      <SubjectsContainer className="d-flex flex-row flex-wrap">
        {selectedUniversity?.map((a: any) => (
          <BadgeCon>
            <BadgeText>
              {a.name.length > 20
                ? `${a.name.replace(/-/g, " ").substring(0, 20)}...`
                : a.name}
            </BadgeText>
            <CloseIcon
              onClick={() => {
                const deleted = selectedUniversity?.filter(
                  (z: any) => z.id !== a.id
                );
                setselectedUniversity(deleted);
                seterrorMessage("");
                // setselectedUniversity(
                //   ...selectedUniversity.filter((z: any) => z.id === a.id)
                // );
              }}
              width="10px"
              height="10px"
            />
          </BadgeCon>
        ))}
      </SubjectsContainer>
    </div>
    //   {errorMessage ? (
    //     <ErrorMessage>{errorMessage}</ErrorMessage>
    //   ) : null}
  );
};
