import * as React from "react";

interface ILogoProps {
  color?: string;
  style?: object;
}

export const Logo: React.FunctionComponent<ILogoProps> = (props) => {
  return (
    <svg
      width={123}
      height={39}
      viewBox="0 0 123 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 9.19824L0.0184403 19.631C0.0184403 22.5669 1.04168 25.0559 3.08815 27.0982C5.16021 29.1406 7.69272 30.1617 10.6857 30.1617C13.7042 30.1617 16.2367 29.1406 18.2832 27.0982C20.3553 25.0559 21.3913 22.5669 21.3913 19.631L21.3729 9.19824H18.8212H16.2695L16.2694 19.631C16.2694 21.2649 15.7635 22.2892 14.7147 23.4124C13.6659 24.5102 12.3229 25.1836 10.6857 25.1836C9.04851 25.1836 7.70551 24.5102 6.65669 23.4124C5.63345 22.2892 5.10338 21.2904 5.10338 19.631L5.10339 9.19824H2.5517H0Z"
        fill={props.color ? props.color : "#333333"}
      />
      <path
        d="M0.427734 5.13391H4.706L2.56888 0L0.427734 5.13391Z"
        fill={props.color ? props.color : "#333333"}
      />
      <path
        d="M16.9957 4.30058L17.0911 4.39605C18.075 5.37986 19.6701 5.37987 20.6539 4.39606C21.6377 3.41224 21.6377 1.81717 20.6539 0.833354L20.5584 0.737859C19.5746 -0.245955 17.9795 -0.245952 16.9957 0.737863C16.0119 1.72168 16.0119 3.31677 16.9957 4.30058Z"
        fill={props.color ? props.color : "#333333"}
      />
      <path
        d="M34.2461 8.61557C30.4097 8.61557 27.2979 10.1111 24.783 13.1448L28.4915 16.264C30.026 14.213 32.0295 13.1021 34.1608 13.1021C36.5906 13.1021 38.1251 14.5121 38.1251 16.8195V17.4177H32.882C30.4949 17.4177 28.7046 17.7168 27.2979 18.3577C25.2519 19.2978 24.1436 21.1778 24.1436 23.6561C24.1436 27.6726 27.2979 30.5355 31.7311 30.5355C34.5019 30.5355 36.889 29.339 38.1251 27.3735L38.5514 30.1082H43.2403V16.8195C43.2403 11.7348 39.7876 8.61557 34.2461 8.61557ZM32.541 26.1771C30.4949 26.1771 29.2588 25.2371 29.2588 23.7416C29.2588 22.9725 29.5572 22.3742 30.1113 21.9897C30.7933 21.4769 31.8164 21.2633 33.5641 21.2633H38.1251C38.1251 23.9979 35.6102 26.1771 32.541 26.1771Z"
        fill={props.color ? props.color : "#333333"}
      />
      <path
        d="M58.557 8.61557C56.2978 8.61557 53.9533 9.34196 52.504 11.1366V9.04285H47.3888V38.8248H52.504V27.9717C53.8254 29.5527 56.1699 30.5355 58.557 30.5355C63.7148 30.5355 67.4234 26.4762 67.4234 19.5969C67.4234 13.273 64.269 8.61557 58.557 8.61557ZM57.2782 26.0489C53.6123 26.0489 52.0351 23.2288 52.0351 19.5541C52.0351 15.794 53.6123 13.1021 57.3208 13.1021C60.731 13.1021 62.3081 15.4094 62.3081 19.5541C62.3081 23.6561 60.6883 26.0489 57.2782 26.0489Z"
        fill={props.color ? props.color : "#333333"}
      />
      <path
        d="M81.7136 8.61557C79.4544 8.61557 77.11 9.34196 75.6606 11.1366V9.04285H70.5454V38.8248H75.6606V27.9717C76.9821 29.5527 79.3265 30.5355 81.7136 30.5355C86.8715 30.5355 90.58 26.4762 90.58 19.5969C90.58 13.273 87.4256 8.61557 81.7136 8.61557ZM80.4348 26.0489C76.7689 26.0489 75.1917 23.2288 75.1917 19.5541C75.1917 15.794 76.7689 13.1021 80.4775 13.1021C83.8876 13.1021 85.4648 15.4094 85.4648 19.5541C85.4648 23.6561 83.845 26.0489 80.4348 26.0489Z"
        fill={props.color ? props.color : "#333333"}
      />
      <path
        d="M98.391 30.5355C99.3714 30.5355 100.522 30.3218 101.673 29.8945V25.7926C101.077 25.9635 100.522 26.0489 100.053 26.0489C99.1157 26.0489 98.5615 25.6644 98.5615 24.7243V0.326172H93.4463V25.0662C93.4463 28.8263 95.535 30.5355 98.391 30.5355Z"
        fill={props.color ? props.color : "#333333"}
      />
      <path
        d="M105.523 38.8248H111.065L123 9.04285H117.374L112.003 23.6561L106.674 9.04285H101.048L109.274 29.5527L105.523 38.8248Z"
        fill={props.color ? props.color : "#333333"}
      />
    </svg>
  );
};
