import styled from "styled-components";

export type TextProps = {
  arabic?: boolean;
};
export const Text = styled.div<TextProps>(
  ({ arabic }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #676767;
    direction: ${arabic ? "rtl" : "ltr"};
    text-align: ${arabic ? "right" : "left"};
  
  `
);

export type InputComponentProps = {
  arabic?: boolean;
};
export const InputComponent = styled.input<InputComponentProps>(
  ({ arabic }) => `
    text-align:${arabic ? "right" : "left"};
     direction:${arabic ? "rtl" : "ltr"};
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    margin-bottom: 15px;

    ::placeholder {
      font-family: ${arabic ? "Cairo" : "Dm Sans"};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
        direction:${arabic ? "rtl" : "ltr"};
      color: #99a5b4;
    
    }
    
          `
);

export const SubjectsContainer = styled.div`
  width: 392px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const BadgeCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  width: max-content;
  background: #e7eaee;
  border-radius: 61px;
  margin: 5px 5px 0 0;
`;

export const BadgeText = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #191d23;
`;
