// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// export const Button = styled.button`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   padding: 9px 17px;
//   width: 135px;
//   height: 43.94px;
//   transition: all 0.3s linear;
//   background: ${(props) =>
//     props.color === "dark" ? ({ theme }) => theme.primaryColor : "#fff"};
//   border: 1px solid
//     ${(props) =>
//       props.color === "dark" ? ({ theme }) => theme.primaryColor : "#1E2432"};
//   box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
//   border-radius: 8px;
//   font-family: "Cairo";
//   font-style: normal;
//   font-weight: 700;
//   font-size: 18px;
//   line-height: 24px;
//   text-transform: capitalize;
//   color: ${(props) =>
//     props.color === "light" ? ({ theme }) => theme.text : "#fff"};
//   &:hover {
//     background: ${(props) =>
//       props.color === "light" ? ({ theme }) => theme.primaryColor : "#fff"};
//     color: ${(props) =>
//       props.color === "dark" ? ({ theme }) => theme.text : "#fff"};
//     border: 1px solid
//       ${() =>
//         ({ theme }) =>
//           theme.primaryColor};
//   }
//   @media (max-width: 992px) {
//     width: 100% !important;
//   }
// `;

export type ButtonProps = {
  disableBtnStyle?: boolean;
  btnType?: string;
  width?: string;
  loading?: number;
};
export const Button = styled.button<ButtonProps>(
  ({ disableBtnStyle, btnType, width, loading }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: ${width};
  height: 43.94px;
  transition: all 0.3s linear;
  background: ${
    loading
      ? "#fff"
      : disableBtnStyle
      ? "#E7EAEE"
      : btnType === "dark"
      ? "#FD3E60"
      : "#fff"
  };
  border:  ${
    disableBtnStyle
      ? "none"
      : `1px solid
    ${btnType === "dark" ? "#FD3E60" : "#1E2432"}`
  };
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color:   ${
    disableBtnStyle ? "#A0ABBB" : btnType === "light" ? "#1D2939" : "#fff"
  };
  &:hover {
    background: ${
      disableBtnStyle ? "#E7EAEE" : btnType === "light" ? "#FD3E60" : "#fff"
    };
    cursor: ${disableBtnStyle ? "not-allowed" : "pointer"};
    color: ${
      disableBtnStyle ? "#A0ABBB" : btnType === "dark" ? "#1D2939" : "#fff"
    };
    border: ${disableBtnStyle ? "none" : `1px solid "#1D2939"`};
  }
  @media (max-width: 992px) {
    width: 100% !important;
  }

  
        `
);
