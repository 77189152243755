import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import Data from "./DataReducer";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import ReduxThunk from "redux-thunk";

// import loading from './LoadingReducer'
// import bugsnag from './bugsnagReducer'
const reducers: any = combineReducers({
  data: Data,
});
const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};
const persistedReducer = persistReducer(persistConfig, reducers);

// const reducers = persistCombineReducers(persistConfig, {
//   auth: Auth,
//   //   data: Data,
// });
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [ReduxThunk],
});

export default store;
export type State = ReturnType<typeof reducers>;
