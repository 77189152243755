export const config = {
  url: {
    API_URL: process.env.REACT_APP_API_ENDPOINT,
  },
};

console.log(process.env.REACT_APP_API_ENDPOINT);

export const SEARCH_SUBJECT = `${config.url.API_URL}/subjects/search`;
export const SEARCH_COUNTRY = `${config.url.API_URL}/countries/search`;
export const STUDENTS = `${config.url.API_URL}/students`;
export const UNIVERSITIES = `${config.url.API_URL}/universities`;
export const LEADS = `${config.url.API_URL}/leads`;
