import React from "react";
import Lottie from "react-lottie";
import animationData from "../Lottie/loading.json";

interface ILoaderProps {}

export const Loader: React.FunctionComponent<ILoaderProps> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={{}}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};
