import Axios from "axios";
import {
  SEARCH_COUNTRY,
  LEADS,
  SEARCH_SUBJECT,
  STUDENTS,
  UNIVERSITIES,
} from "../API";

export const searchSubjects = async (data: any) => {
  try {
    const res = await Axios.get(`${SEARCH_SUBJECT}/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchUniversities = async (data: any) => {
  try {
    const res = await Axios.get(`${UNIVERSITIES}/search/${data.text}`, {
      params: {
        limit: data.limit,
        sortBy: data.sortBy,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchCountries = async (data: any) => {
  try {
    const res = await Axios.get(`${SEARCH_COUNTRY}/${data.text}`, {
      params: {
        limit: data.limit,
        sortBy: data.sortBy,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createStudent = async (data: object) => {
  try {
    const res = await Axios.post(`${STUDENTS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createLeads = async (data: object) => {
  try {
    const res = await Axios.post(`${LEADS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
