import { LANG, SHOW } from "./types";
import { Dispatch } from "redux";

export const updateLanguage = (lang: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = lang;

      dispatch({
        type: LANG,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const showAnimation = (show: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: SHOW,
        payload: show,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
