/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useTranslation } from "react-i18next";

import { CircleCheck, IndexIcon } from "../Assets";
import IndexIconEn from "../Assets/Icons/IndexIconEn";
import { ButtonComponent } from "../Components";
import { ModalComponent } from "../Components/Modal";
import { Con, HeaderText, Subtext, TextCon } from "./styled/home";

interface IHomeProps {}

export const Home: React.FunctionComponent<IHomeProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [show, setshow] = React.useState(false);
  localStorage.removeItem("degree");
  localStorage.clear();

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    i18n.changeLanguage(i18n.language);
  }, []);
  const arabic = i18n.language === "ar";

  return (
    <Con>
      {!arabic ? (
        <div
          className="pc-view"
          style={{
            position: "absolute",
            right: 0,
            bottom: "-34px",
            // zIndex: 99,
          }}
        >
          <IndexIconEn />
        </div>
      ) : (
        <div
          className="pc-view"
          style={{
            position: "absolute",
            left: 0,
            bottom: "-40px",
          }}
        >
          <IndexIcon />
        </div>
      )}

      <TextCon
        direction={arabic ? "flex-end" : "flex-start"}
        className="container"
      >
        <HeaderText arabic={arabic}>{t("common:homeHeader1")}</HeaderText>
        <HeaderText arabic={arabic}>{t("common:homeHeader2")}</HeaderText>
        <div
          style={{ marginTop: "30px" }}
          className={`d-flex flex-column ${
            arabic ? "align-items-end" : "align-items-start"
          }`}
        >
          <div>
            {" "}
            <Subtext fontWeight={700} arabic={arabic}>
              {t("common:applySteps")}
            </Subtext>
          </div>
          <div
            className={`flex-column container d-flex justify-content-center ${
              arabic ? "align-items-end" : "align-items-start"
            } h-100`}
          >
            <div
              className={`d-flex align-items-center ${
                arabic ? "flex-row" : "flex-row-reverse"
              }`}
              style={{ marginTop: 6 }}
            >
              <Subtext fontWeight={500} arabic={arabic}>
                {t("common:applyStep1")}
              </Subtext>
              <CircleCheck
                width={22}
                height={22}
                style={{ margin: !arabic ? "0 10px 0 0" : "0 0 0 10px" }}
              />
            </div>
            <div
              className={`d-flex align-items-center ${
                arabic ? "flex-row" : "flex-row-reverse"
              }`}
            >
              <Subtext fontWeight={500} arabic={arabic}>
                {t("common:applyStep2")}
              </Subtext>
              <CircleCheck
                width={22}
                height={22}
                style={{ margin: !arabic ? "0 10px 0 0" : "0 0 0 10px" }}
              />
            </div>
            <div
              className={`d-flex align-items-center ${
                arabic ? "flex-row" : "flex-row-reverse"
              }`}
            >
              <Subtext fontWeight={500} arabic={arabic}>
                {t("common:applyStep3")}
              </Subtext>
              <CircleCheck
                width={22}
                height={22}
                style={{ margin: !arabic ? "0 10px 0 0" : "0 0 0 10px" }}
              />
            </div>
            <ButtonComponent
              style={{
                marginTop: 29,
                padding: "0 58px",
                width: "90%",
                zIndex: 99,
                marginBottom: 30,
              }}
              width="100%"
              className="pc-view"
              label={t("common:getStarted")}
              loading={false}
              btnType={"dark"}
              onClick={() => setshow(true)}
            />
          </div>
        </div>
        <ButtonComponent
          style={{
            marginTop: 29,
            padding: "0 58px",
            width: "90%",
            zIndex: 99,
            marginBottom: 30,
          }}
          width="100%"
          className="res-home-btn mobile-view"
          label={t("common:getStarted")}
          loading={false}
          btnType={"dark"}
          onClick={() => setshow(true)}
        />
      </TextCon>
      <div
        className="mobile-view"
        style={{ position: "relative", bottom: 0, zIndex: 99 }}
      >
        {/* <IndexIconMobile /> */}
        <img
          style={{ width: "100%" }}
          src={
            arabic
              ? "https://storage.googleapis.com/uapply/index-banner-res-ar1.png"
              : "https://storage.googleapis.com/uapply/index-banner-res1.png"
          }
          alt="img"
        />
      </div>
      <ModalComponent show={show} onHide={() => setshow(!show)} />
    </Con>
  );
};

//? Live Soon Design

/* <Con
      style={{ height: "80vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <HeaderText arabic={arabic}>{t("common:comingSoon")}</HeaderText>
    </Con> */
