/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Navbar, Container } from "react-bootstrap";
import { Logo } from "../../Assets";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { LangCon } from "./styled";
import { useLocation, useNavigate } from "react-router-dom";

interface IHeaderProps {}

export const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const { i18n } = useTranslation();
  const [showLang, setshowLang] = React.useState(false);
  const navigate = useNavigate();
  const loacation = useLocation();
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const [lang, setlang] = React.useState(
    loacation.pathname.includes("en") ? "en" : "ar"
  );

  React.useEffect(() => {
    i18n.changeLanguage(loacation.pathname.includes("en") ? "en" : "ar");
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const changeLanguageHandler = (lang: string) => {
    setlang(lang);
    i18n.changeLanguage(lang);
    setshowLang(false);
  };

  return (
    <Navbar
      style={{
        backgroundColor:
          loacation.pathname.includes("results") || scrollPosition !== 0
            ? "#fff "
            : "transparent ",
        boxShadow:
          scrollPosition === 0 ? "none" : "0px 4px 16px rgb(166 171 180 / 16%)",
      }}
      // bg="light"
      expand="lg"
      sticky="top"
    >
      <Container
        className={
          lang === "ar"
            ? "d-flex flex-row-reverse justify-content-between"
            : "d-flex flex-row justify-content-between"
        }
      >
        {/* <Navbar.Toggle>
          <ToggleIcon />
        </Navbar.Toggle> */}
        <div className="mobile-view">
          <LangCon
            onClick={() => {
              changeLanguageHandler(lang === "ar" ? "en" : "ar");
              if (loacation.pathname.includes("results")) {
              } else {
                navigate(`${lang === "ar" ? "/en" : "/ar"}`);
              }
            }}
          >
            {lang === "ar" ? "En" : "Ar"}
          </LangCon>
          {/* {showLang ? (
            <LangMenu>
              <Nav.Link
                // style={{ padding: "0 20px" }}
                onClick={() => changeLanguageHandler("ar")}
              >
                Arabic
              </Nav.Link>
              <Nav.Link
                // style={{ padding: "0 20px" }}
                onClick={() => changeLanguageHandler("en")}
              >
                English
              </Nav.Link>
            </LangMenu>
          ) : null} */}
        </div>

        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>
        <div className="mobile-view">
          <div
            className={`${
              lang === "en" ? "justify-content-end" : "justify-content-start"
            } d-flex align-items-center `}
            style={{ width: 60, height: 60 }}
          >
            {/* <img
              src="https://storage.googleapis.com/destination_ulearn/courses/WhatsApp_Logo_1%201.png"
              alt="icon"
              width={"28px"}
              height="28px"
            /> */}
          </div>
        </div>
        <Navbar.Collapse
          style={{
            border: "none",
            justifyContent: lang === "ar" ? "flex-start" : "flex-end",
          }}
          id="basic-navbar-nav"
        >
          <Navbar
            className={`d-flex  align-items-start ${
              lang !== "ar" ? "flex-row" : "flex-row-reverse"
            }`}
            style={{ position: "relative" }}
          >
            <LangCon
              onClick={() => {
                changeLanguageHandler(lang === "ar" ? "en" : "ar");
              }}
            >
              {lang === "ar" ? "En" : "Ar"}
            </LangCon>
            {/* {showLang ? (
              <LangMenu>
                <Nav.Link
                  // style={{ padding: "0 20px" }}
                  onClick={() => changeLanguageHandler("ar")}
                >
                  Arabic
                </Nav.Link>
                <Nav.Link
                  // style={{ padding: "0 20px" }}
                  onClick={() => changeLanguageHandler("en")}
                >
                  English
                </Nav.Link>
              </LangMenu>
            ) : null} */}
            {/* <img
              src="https://storage.googleapis.com/destination_ulearn/courses/WhatsApp_Logo_1%201.png"
              alt="icon"
              width={"28px"}
              height="28px"
              className="pc-view"
            /> */}
          </Navbar>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
