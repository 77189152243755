/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { darkTheme, GlobalStyles, Header, lightTheme } from "./Components";
import { Route, Routes, useLocation } from "react-router";
import { Home, Result } from "./pages";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [theme, setTheme] = useState("light");

  const loacation = useLocation();

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      {loacation.pathname === "/on-boarding" ? null : <Header />}
      {/* <button style={{ position: "absolute" }} onClick={themeToggler}>
      Switch Theme
    </button> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:language" element={<Home />} />
        <Route path="/:language/results" element={<Result />} />
        {/* <Route path="/on-boarding" element={<OnBoarding />} /> */}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
