import * as React from "react";

interface IChatIconProps {
  style?: object;
}

export const ChatIcon: React.FunctionComponent<IChatIconProps> = (props) => {
  return (
    <svg
      width={74}
      height={74}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70.6875 50.875C70.6875 59.9014 63.3702 67.2188 54.3438 67.2188C45.3173 67.2188 38 59.9014 38 50.875C38 41.8486 45.3173 34.5312 54.3438 34.5312C63.3702 34.5312 70.6875 41.8486 70.6875 50.875Z"
        fill="white"
        stroke="white"
        strokeWidth={2}
      />
      <path
        d="M54.2932 53.5899C54.2932 51.1768 56.1947 50.7729 56.8495 50.1198C57.5064 49.4645 57.9128 48.5583 57.9128 47.5571C57.9128 45.5581 56.2922 43.9375 54.2932 43.9375C53.2122 43.9375 52.2419 44.4113 51.5787 45.1626C51.272 45.51 51.031 45.9168 50.875 46.3634"
        stroke="black"
        strokeWidth={2}
        strokeLinecap="square"
      />
      <circle cx={54.3438} cy={57.8125} r={1.15625} fill="black" />
      <path
        d="M54.3438 33.5312C57.7591 33.5265 61.099 34.5353 63.9406 36.43C64.4766 34.5891 64.7491 32.6816 64.75 30.7643C64.75 16.9633 50.7733 5.78125 33.5312 5.78125C16.2893 5.78125 2.3125 16.9633 2.3125 30.7563C2.3125 37.7724 5.93503 44.1109 11.7591 48.6481L4.625 62.4375L24.9923 54.7773C27.7942 55.4126 30.6583 55.7325 33.5312 55.7313C34.9003 55.7311 36.2685 55.6597 37.6302 55.5173L37.6452 55.5069C36.9218 52.9319 36.8054 50.2242 37.3052 47.5966C37.805 44.969 38.9073 42.4931 40.5255 40.3634C42.1438 38.2338 44.2338 36.5084 46.6315 35.323C49.0291 34.1375 51.669 33.5242 54.3438 33.5312Z"
        fill="white"
      />
      <path
        opacity={0.15}
        d="M42.0678 38.5875L38.6188 42.0319L38.5945 42.0562L37.9412 42.7095L37.9886 42.7234C35.1178 45.8981 33.5292 50.0264 33.5313 54.3067C33.5313 54.7692 33.5648 55.2317 33.6018 55.6942C34.9477 55.6922 36.2926 55.6216 37.6313 55.4826L37.6463 55.4722C36.8152 52.5179 36.7873 49.3953 37.5655 46.4266C38.3438 43.4579 39.9 40.7506 42.0736 38.584L42.0678 38.5875Z"
        fill="white"
      />
    </svg>
  );
};
