// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const LineCon = styled.div`
  position: relative;
  top: 0;
  transition: 0.9s ease;
  @media (max-width: 992px) {
    top: 0;
  }
`;
