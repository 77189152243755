import * as React from "react";

interface ICircleCheckProps {
  style?: object;
  width?: number;
  height?: number;
}

export const CircleCheck: React.FunctionComponent<ICircleCheckProps> = (
  props
) => {
  return (
    <svg
      width={props.width ? props.width : 20}
      height={props.height ? props.height : 20}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1423_47194)">
        <path
          d="M13.75 6.92516V7.50016C13.7492 8.84792 13.3128 10.1593 12.5058 11.2388C11.6989 12.3183 10.5646 13.108 9.2721 13.4901C7.97964 13.8722 6.59829 13.8263 5.33404 13.3593C4.0698 12.8922 2.99041 12.029 2.25685 10.8983C1.52329 9.76768 1.17487 8.4302 1.26355 7.08536C1.35223 5.74051 1.87325 4.46036 2.74892 3.43583C3.6246 2.4113 4.80799 1.69727 6.12262 1.40025C7.43725 1.10323 8.81267 1.23912 10.0438 1.78766"
          stroke="#299CF7"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.75 2.5L7.5 8.75625L5.625 6.88125"
          stroke="#299CF7"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1423_47194">
          <rect width={15} height={15} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
