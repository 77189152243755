import * as React from "react";

interface IBackIconProps {}

export const BackIcon: React.FunctionComponent<IBackIconProps> = (props) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5002 24.1148C17.2785 24.1148 17.0568 24.0331 16.8818 23.8581L9.27516 16.2514C8.03849 15.0148 8.03849 12.9848 9.27516 11.7481L16.8818 4.14145C17.2202 3.80311 17.7802 3.80311 18.1185 4.14145C18.4568 4.47978 18.4568 5.03978 18.1185 5.37811L10.5118 12.9848C9.95182 13.5448 9.95182 14.4548 10.5118 15.0148L18.1185 22.6214C18.4568 22.9598 18.4568 23.5198 18.1185 23.8581C17.9435 24.0214 17.7218 24.1148 17.5002 24.1148Z"
        fill="#B8C0CC"
      />
    </svg>
  );
};
