import * as React from "react";
import { Loader } from "../Loader/Loader";
import { Button } from "./styled";

interface IButtonComponentProps {
  label: string;
  loading?: any;
  btnType: string;
  style?: object;
  onClick?: Function;
  className?: string;
  disableBtnStyle?: boolean;
  width: string;
}

export const ButtonComponent: React.FunctionComponent<IButtonComponentProps> = (
  props
) => {
  return (
    <Button
      style={props.style}
      width={props.width}
      disabled={props.loading || props.disableBtnStyle}
      className={props.className}
      btnType={props.btnType}
      type="submit"
      loading={props.loading ? 1 : 0}
      onClick={(e) => props.onClick?.(e)}
      disableBtnStyle={props.disableBtnStyle}
    >
      {props.loading ? <Loader /> : props.label}
    </Button>
  );
};
