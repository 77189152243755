import * as React from "react";
import Slider from "react-slick";
import { SliderArrowRight } from "../../Assets";
import { ImgContainer, SliderImgCon, Subtext } from "./styled";

interface IUnoversitySliderProps {
  arabic?: boolean;
}

export const UniversitySlider: React.FunctionComponent<
  IUnoversitySliderProps
> = (props) => {
  const [slider, setslider] = React.useState<any>("");
  const [activeSlide, setactiveSlide] = React.useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    variableWidth: false,
    arrows: true,
    // fade: true,
    slidesToScroll: 1,
    rows: 2,
    afterChange: (current: any) => setactiveSlide(current),
    // centerMode: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerPadding: "40px",
          slidesToShow: 2,
          rows: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          infinite: true,
          centerPadding: "40px",
          slidesToShow: 1,
          rows: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <div style={{ position: "relative" }}>
      <Slider ref={(c: any) => setslider(c)} {...settings}>
        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/edinburgh-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/edinburgh-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              The University of Edinburgh
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Edinburgh, Scotland
            </Subtext>
          </div>
        </div>
        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/queen-mary-london.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/queen-mary-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              Queen Mary University
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              London, England
            </Subtext>
          </div>
        </div>

        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/northumbria-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/northumbria-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              Northumbria University
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Newcastle, England
            </Subtext>
          </div>
        </div>

        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/bangor-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/bangor-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              Bangor University
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Bangor, Wales
            </Subtext>
          </div>
        </div>

        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/queen-marry-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/belfast-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              Queen’s University
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Belfast, Northern Ireland
            </Subtext>
          </div>
        </div>
        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/bristol-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/bristol-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              University of Bristol
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Bristol, England
            </Subtext>
          </div>
        </div>
        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/cardiff-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/cardiff-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              Cardiff University
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Cardiff, Wales, United Kingdom
            </Subtext>
          </div>
        </div>

        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/liverpool-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/liverpool-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              University of Liverpool
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Liverpool, England
            </Subtext>
          </div>
        </div>

        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/northumbria-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/nottingham-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              The University of Nottingham
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Nottingham, United Kingdom
            </Subtext>
          </div>
        </div>

        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/portsmouth-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/portsmouth-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              University of Portsmouth
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Portsmouth, England{" "}
            </Subtext>
          </div>
        </div>

        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/westminster-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/westminster-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              University of Westminster
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              London, United Kingdom
            </Subtext>
          </div>
        </div>
        <div>
          <SliderImgCon>
            <img
              src="https://storage.googleapis.com/uapply/salford-logo.png"
              alt="icon"
              className="slider-uni-logo"
            />
            <ImgContainer background="url('https://storage.googleapis.com/uapply/salford-min.png')" />
          </SliderImgCon>

          <div style={{ margin: "11px 0 20px 0" }}>
            <Subtext
              arabic={props.arabic}
              fontSize="18px"
              lineHeight="20px"
              fontWeight="700"
            >
              University of Salford
            </Subtext>
            <Subtext
              arabic={props.arabic}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
            >
              Manchester, England
            </Subtext>
          </div>
        </div>
      </Slider>

      <article
        style={{ padding: "40px 0", position: "absolute", top: "40%" }}
        className="d-flex justify-content-center w-100 pc-view"
      >
        <div>
          {/* <div onClick={() => slider.slickPrev()}> */}
          {activeSlide === 0 ? null : (
            <div onClick={() => slider.slickPrev()}>
              <SliderArrowRight
                style={{
                  transform: "rotate(180deg)",
                  cursor: "pointer",
                  position: "absolute",
                  left: 30,
                }}
              />
            </div>
          )}

          {/* </div> */}

          {/* <div onClick={() => slider.slickNext()}> */}
          {activeSlide === 3 ? null : (
            <div onClick={() => slider.slickNext()}>
              <SliderArrowRight
                style={{ cursor: "pointer", position: "absolute", right: 30 }}
              />
            </div>
          )}
          {/* </div> */}
        </div>
      </article>

      <article
        style={{ margin: "0" }}
        className="d-flex justify-content-center w-100 mobile-view"
      >
        <div className="d-flex flex-row justify-content-center w-100">
          {/* <div onClick={() => slider.slickPrev()}> */}

          <div onClick={() => slider.slickPrev()}>
            <SliderArrowRight
              style={{
                transform: "rotate(180deg)",
                cursor: "pointer",
              }}
            />
          </div>

          {/* </div> */}

          {/* <div onClick={() => slider.slickNext()}> */}

          <div onClick={() => slider.slickNext()}>
            <SliderArrowRight style={{ cursor: "pointer", marginLeft: 20 }} />
          </div>

          {/* </div> */}
        </div>
      </article>
    </div>
  );
};
