// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const HeaderText = styled.div`
  font-family: "Ara Asmaa Beltajie";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 104px;
  text-align: right;
  letter-spacing: -1.87579px;
  color: #1e2432;
`;

export const LangCon = styled.div`
  background: #fd3e60;
  border-radius: 18px;
  width: 60px;
  height: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  margin: 0 20px;
  cursor: pointer;
  z-index: 999;
  &:hover {
    background: #fff;
    color: #fd3e60;
    border: 1px solid #fd3e60;
  }
  @media (max-width: 992px) {
    margin: 0;
  }
`;

export const LangMenu = styled.div`
  background: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50px;
  width: 100px;
  box-shadow: 0px 4px 16px rgb(166 171 180 / 16%);
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  &:hover {
  }
`;
