import React from "react";
import { Accordion } from "react-bootstrap";
import { FaqText } from "./styled";

interface IFaqComponentProps {
  answer: string;
  question: string;
  customPadding: string;
  eventKeyProps: string;
  arabic: boolean;
}

export const FaqComponent: React.FunctionComponent<IFaqComponentProps> = (
  props
) => {
  const { answer, question, eventKeyProps, arabic } = props;
  return (
    <div style={{ borderBottom: "1px solid #E3E9EF !important" }}>
      <Accordion.Item eventKey={eventKeyProps}>
        <Accordion.Header
          className={arabic ? "accordion-btn-ar" : "accordion-btn-en"}
        >
          <div
            style={{
              direction: arabic ? "rtl" : "ltr",
              fontFamily: arabic ? "Cairo" : "Dm Sans",
            }}
          >
            {question}
          </div>
        </Accordion.Header>
        <Accordion.Collapse
          style={{ direction: arabic ? "rtl" : "ltr" }}
          eventKey={eventKeyProps}
        >
          <div>
            <div style={{ marginBottom: 24, textAlign: "start" }}>
              <FaqText arabic={arabic}>{answer}</FaqText>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion.Item>
    </div>
  );
};
