import * as React from "react";
import { CloseIcon } from "../../Assets";
import { InputComponent, Text } from "./styled";

interface ICountryInputComponentProps {
  selectedCountry: any;
  setsearchText: any;
  setselectedCountry: any;
  arabic: boolean;
  searchCountry: any;
  countries: any;
  setcountries: any;
  searchText: string;
  placeholder: string;
  className?: string;
}

export const CountryInputComponent: React.FunctionComponent<
  ICountryInputComponentProps
> = (props) => {
  const {
    selectedCountry,
    setsearchText,
    setselectedCountry,
    arabic,
    searchCountry,
    countries,
    searchText,
    setcountries,
    placeholder,
  } = props;

  let con = countries;

  return (
    <div className="d-flex w-100 justify-content-center">
      <div
        className="d-flex res-w-100 flex-row"
        style={{ position: "relative" }}
      >
        {Object.keys(selectedCountry).length ? (
          <div
            onClick={() => {
              setselectedCountry({});
              setsearchText("");
              setcountries([]);
            }}
            style={{
              right: !arabic ? 10 : "auto",
              left: !arabic ? "auto" : 10,
            }}
            className="input-cross"
          >
            <CloseIcon />
          </div>
        ) : null}
        {Object.keys(selectedCountry).length ? (
          <InputComponent
            name="contry"
            type="text"
            className={`form-input ${props.className}`}
            style={{ textAlign: arabic ? "right" : "left" }}
            value={
              arabic
                ? selectedCountry.arabic_name
                : selectedCountry.english_name
            }
            arabic={arabic}
          />
        ) : (
          <InputComponent
            placeholder={placeholder}
            name="name"
            onChange={(e) => {
              setsearchText(e.target.value);
              searchCountry(e);
            }}
            type="text"
            className={`search-input ${props.className}`}
            autoComplete="off"
            value={searchText}
            arabic={arabic}
          />
        )}
        {con.length && !Object.keys(selectedCountry).length ? (
          <div
            className="d-flex flex-column"
            style={{
              background: "#fff",
              position: "absolute",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              borderRadius: 8,
              top: 60,
              width: "100%",
              padding: "4px 0 4px 0",
            }}
          >
            {con.map((a: any) => (
              <Text
                key={a.id}
                onClick={async () => {
                  setsearchText("");
                  setselectedCountry(a);
                  await setcountries([]);
                  con = [];
                }}
                className="custom-dd-menu"
                style={{
                  padding: "5px 15px",
                }}
                arabic={arabic}
              >
                {arabic ? a.arabic_name : a.english_name}
              </Text>
            ))}{" "}
          </div>
        ) : searchText && !countries.length ? (
          <div
            className="d-flex flex-column"
            style={{
              background: "#fff",
              position: "absolute",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              borderRadius: 8,
              top: 60,
              width: "100%",
              padding: "10px 0 10px 0",
            }}
          >
            {" "}
            <div
              className="d-flex justify-content-center"
              style={{ color: "#969696" }}
            >
              Loading...
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
